import { Box, Button, Typography } from '@mui/material';

export default function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} role="alert">
                <Typography variant="subtitle1" component={'h2'} color={'error'}>
                    Oops Something went wrong:
                </Typography>
                <pre>{error.message}</pre>
                <Button variant="contained" color="error" onClick={resetErrorBoundary}>
                    Try again
                </Button>
            </Box>
        </Box>
    );
}

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import useRoleHandler from '../hooks/useGetRole';
import Cookies from 'js-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/errorFallBack';

const PrivateRoute = ({ children }) => {
  const customization = useSelector((state) => state.customization);
  const Authed = customization['Authed'];
  const token = Cookies.get('admintoken');

  const handleError = React.useCallback((error, info) => {
    console.error('Error caught by ErrorBoundary:', error);
    console.error('Error details:', info);
  }, []);

  if (token) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        {children}
      </ErrorBoundary>
    );
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default PrivateRoute;
